/**
 * TODO
 * @category Utils
 */
export function buildClassNames(styles: { [key: string]: string }, classNames: any[]): string {
    return classNames
        .filter(className => className && styles[className])
        .map(className => styles[className])
        .join(' ');
}

/**
 * TODO
 * @category Utils
 */
export function mergeClassNames(classNames: any[]): string {
    return classNames
        .filter(className => className)
        .join(' ');
}

export const cnx = mergeClassNames;